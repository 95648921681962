<template>
  <ButtonButton
    :theme="themeComp"
    :size="size"
    :is-loading="showConnectModal"
    :disabled="showConnectModal"
    @click="showConnect"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <span>{{ text }}</span>

    <div
      v-if="hover && hoverEnabled"
      class="hidden lg:block bg-slate-700 absolute top-14 p-4 rounded-xl animate-slide-down-fade-in"
      :class="[
        hoverTooltipClassesComp
      ]"
    >
      <h2 class="text-white text-lg mb-2 leading-tight animate-fade-in">Why connect?</h2>
      <p class="text-slate-100 text-sm normal-case tracking-normal animate-fade-in">MetaWin runs on the blockchain. Taking part requires a personal signature. No private data is shared with us. We will NEVER ask for an ‘Approve All’ state.</p>
    </div>
  </ButtonButton>
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    default: null,
  },
  hoverEnabled: {
    type: Boolean,
    default: false,
  },
  hoverTooltipClasses: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: null,
  },
  web3Only: {
    type: Boolean,
    default: false,
  },
  noLogin: {
    type: Boolean,
    default: false,
  },
  attachWallet: {
    type: Boolean,
    default: false,
  },
  swap: {
    type: Boolean,
    default: false,
  },
  chainType: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: 'Connect',
  },
});

const uiStore = useUiStore();
const {
  showConnectModal,
  showWalletModal,
} = storeToRefs(uiStore);
const { openConnectModal, } = uiStore;

const authStore = useAuthStore();
const {
  attach,
} = storeToRefs(authStore);

const hover = ref(false);

const hoverTooltipClassesComp = computed(() => !props.hoverTooltipClasses ? 'w-full left-0' : props.hoverTooltipClasses.join(' '));
const themeComp = computed(() => props.theme ? props.theme : 'primary');

function showConnect() {
  attach.value = !!props.attachWallet;
  openConnectModal({
    web3Only: props.web3Only,
    noLogin: props.noLogin,
    swap: props.swap,
    chainType: props.chainType,
    message: 'Experience winning in Web3 by connecting your wallet',
  });

  showWalletModal.value = false;
}
</script>
